import { FC_CANCELLED_BANNER_DISMISSED } from '@/utils/constants';
import { useState, useCallback, useEffect } from 'react';
import Icon from '@/components/ui/icon';
import colors from '@/utils/colors';
import { BsX } from 'react-icons/bs';
import { ForeverCleanStatus } from 'src/__generated__/graphql';
import { formatDate } from '@/utils/helper';
import { benefitsEndsDate } from '@/utils/membershipUtils';
type MembershipCancelledBannerProps = {
  membershipStatus: ForeverCleanStatus;
  paidThrough: string;
};

export function MembershipCancelledBanner({
  membershipStatus,
  paidThrough,
}: MembershipCancelledBannerProps) {
  const [showBanner, setShowBanner] = useState(false);

  const dismissBanner = useCallback((e: any) => {
    e?.preventDefault?.();
    if (typeof localStorage === 'undefined') return;
    localStorage.setItem(FC_CANCELLED_BANNER_DISMISSED, 'true');
    setShowBanner(false);
  }, []);

  useEffect(() => {
    if (typeof localStorage === 'undefined') return;
    const storedRewardsBanner = !localStorage.getItem(
      FC_CANCELLED_BANNER_DISMISSED,
    );
    if (storedRewardsBanner) {
      setShowBanner(true);
    }
  }, []);

  const wasCancelled =
    membershipStatus === ForeverCleanStatus.Cancelled ||
    membershipStatus === ForeverCleanStatus.PendingCancellation;

  if (!showBanner || !wasCancelled) {
    return null;
  }

  const hasMembershipEnded = new Date(paidThrough) < new Date();
  const paidThroughFormatted = formatDate(benefitsEndsDate(paidThrough));

  return (
    <a
      className={
        'flex flex-row items-center justify-between sm:mx-1 md:mx-2 lg:mx-0 mb-2 px-4 py-4 rounded-lg bg-lightestRed'
      }
      href="/settings/membership"
    >
      <div className="flex items-center">
        <Icon name="lightning" size={24} color={colors.brand.black} />
        <div className="ml-2">
          <p>
            <b>
              {hasMembershipEnded && 'Your membership has been cancelled'}
              {!hasMembershipEnded && (
                <>
                  You&apos;ll lose your access to your membership on{' '}
                  {paidThroughFormatted}
                </>
              )}
            </b>
          </p>
          <p>{hasMembershipEnded && 'Reactivate to use your benefits'}</p>
          <p>{!hasMembershipEnded && 'Reactivate to keep your benefits'}</p>
        </div>
      </div>
      <button
        onClick={dismissBanner}
        className="h-11 w-11 flex justify-center items-center"
        aria-label="Close rewards banner"
      >
        <BsX className="h-5 w-5" />
      </button>
    </a>
  );
}
